@font-face {
  font-family: "RoseOfSharon";
  src: url("./static/fonts/RoseOfSharon.ttf");
}

* {
  margin: 0;
  padding: 0;
  font-family: 'RoseOfSharon';
}


@media all and (min-width: 1280px) {
  * {
    font-size: 28px;
  }
}

@media all and (min-width: 1024px) {
  * {
    font-size: 24px;
  }
}

@media all and (min-width: 768px) and (max-width: 1023px) {
  * {
    font-size: 18px;
  }
}

@media all and (min-width: 356px) and (max-width: 767px) {
  * {
    font-size: 12px;
  }
}

@media all and (min-width: 128px) and (max-width: 356px) {
  * {
    font-size: 8px;
  }
}